import React from 'react';
import ButtonRegistration, {ButtonRegistrationStyle} from "pages/Front/Registration/components/ButtonRegistration";
import {PencilIcon, TrashIcon} from "@heroicons/react/24/solid";
import {AccountDriver} from "types/entities/AccountDriver";
import {openModal} from "helpers/actions";
import RegistrationModal from "pages/Front/Registration/components/RegistrationModal";
import RegistrationForm from "pages/Front/Registration/components/RegistrationForm";
import {AccountType} from "types/enums/AccountType";
import {ModalSize} from "pages/Front/components/Popup";
import {useDispatch} from "useDispatch";

interface RegistrationGuestRowProps {
    driver: AccountDriver;
    onRemove: (driver: AccountDriver) => void;
}

const RegistrationGuestRow = (props: RegistrationGuestRowProps) => {
    const dispatch = useDispatch();

    const editGuestHandler = () => {
        console.log("editGuestHandler", props.driver.id);

        dispatch(
            openModal(
                <RegistrationModal
                    className={"sm:min-w-[600px] md:min-w-[728px] lg:min-w-[768px] w-full"}
                    subtitle={"Personal information"}
                    title={"Edit guest to account"}
                >
                    <RegistrationForm
                        defaultValues={props.driver}
                        guestId={props.driver.id}
                        linkSuccess={"/booking/register"}
                        type={AccountType.Guest}
                    />
                </RegistrationModal>,
                {modalSize: ModalSize.Small}
            )
        );
    }
    const deleteGuestHandler = () => {
        console.log("deleteGuestHandler", props.driver.id);
        props.onRemove(props.driver);
    }

    return (
        <div className={"RegistrationGuestRow items-center odd:bg-white even:bg-gray-100 grid grid-cols-4 py-1 border-l border-r border-b border-gray-600 last-of-type:rounded-bl-md last-of-type:rounded-br-md"}>
            <div className={"col-span-1 px-3"}>{props.driver.name}</div>
            <div className={"col-span-1 text-center px-3"}>{props.driver.last_name}</div>
            <div className={"col-span-1 text-center px-3"}>{props.driver.nickname}</div>
            <div className={"col-span-1 pl-3 flex flex-row justify-end"}>
                <ButtonRegistration style={ButtonRegistrationStyle.Transparent} onClick={editGuestHandler}>
                    <PencilIcon className={"w-5 h-5 text-blue-500"} />
                </ButtonRegistration>
                <ButtonRegistration style={ButtonRegistrationStyle.Transparent} onClick={deleteGuestHandler}>
                    <TrashIcon className={"w-5 h-5 text-red-500"} />
                </ButtonRegistration>
            </div>
        </div>
    );
};

export default RegistrationGuestRow;