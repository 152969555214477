import React, {useState} from 'react';
import {useTranslation} from "react-i18next";
import ReactSignatureCanvas from 'react-signature-canvas'
import clsx from "clsx";
import {useDispatch} from "useDispatch";
import {toast} from "react-toastify";
import ButtonRegistration, {ButtonRegistrationStyle} from "pages/Front/Registration/components/ButtonRegistration";
import {CheckIcon, XMarkIcon} from "@heroicons/react/24/solid";
import {AccountDriver, AccountSignatureRequest} from "types/entities/AccountDriver";
import RegistrationAction from "store/registration/RegistrationAction";

interface ReservationSignatureProps {
    setSignature: (image: string) => void;
    account: AccountDriver;
}

const ReservationSignature = (props: ReservationSignatureProps) => {
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const signatureUrl = props.account.signature_url;
    const [isSaving, setIsSaving] = useState<boolean>(false);
    const [isToolsVisible, setIsToolsVisible] = useState<boolean>(signatureUrl === null);
    const [sigPad, setSigPad] = useState<ReactSignatureCanvas>();

    const clearSignatureHandler = () => {
        sigPad && sigPad.clear();
    }

    const saveSignatureHandler = () => {
        setIsSaving(true);
        if (sigPad && sigPad.isEmpty()) {
            toast.warning(t("You must create a signature."));
            setIsSaving(false);
        }
        if (sigPad && !sigPad.isEmpty()) {
            const signatureImage = sigPad.getTrimmedCanvas().toDataURL('image/png');
            const signatureBaseCode = signatureImage.replace("data:image/png;base64,", "");
            const signatureData: AccountSignatureRequest = {
                signature: signatureBaseCode,
                driver_id: props.account.id
            }
            dispatch(RegistrationAction.fetchSetSignature(signatureData))
                .then(r => {
                    props.setSignature(signatureBaseCode);
                    setIsSaving(false);
                    setIsToolsVisible(false);
                    toast.success(t("Signature created."));
                });
        }
    }

    return (
        <div className={"ReservationSignature w-auto h-full flex flex-col self-center"}>
            <h2 className={"text-xl text-center font-medium mb-5"}>
                {t("Signature")}
            </h2>
            <div className={clsx(
                "flex justify-center items-center"
            )}>
                {signatureUrl
                    ? (
                        <div
                            className={"flex justify-center w-[600px] h-[200px] ring-1 ring-gray-600 ring-inset focus:ring-1 focus:ring-black focus:outline-0 border-0 shadow-sm bg-white rounded-lg p-2"}>
                            <img src={signatureUrl} className={"self-center"}/>
                        </div>
                    )
                    : (
                        <ReactSignatureCanvas
                            penColor='black'
                            canvasProps={{
                                className: clsx(
                                    "sigCanvas w-[600px] h-[200px] ring-1 ring-gray-600 ring-inset focus:ring-1 focus:ring-black focus:outline-0 border-0 shadow-sm bg-white rounded-lg",
                                )
                            }}
                            ref={ref => ref && setSigPad(ref)}
                        />
                    )
                }
            </div>
            {isToolsVisible && (
                <div className={clsx("flex flex-row space-x-4 mt-6 w-full justify-between")}>
                    <ButtonRegistration onClick={clearSignatureHandler} style={ButtonRegistrationStyle.Red}>
                        <XMarkIcon className={"w-5 h-5 mr-2"}/>
                        <span>{t("Clear signature")}</span>
                    </ButtonRegistration>
                    <ButtonRegistration onClick={saveSignatureHandler} loading={isSaving}>
                        {!isSaving && <CheckIcon className={"w-5 h-5 mr-2"}/>}
                        <span>{t("Save signature")}</span>
                    </ButtonRegistration>
                </div>
            )}
        </div>
    );
};

export default ReservationSignature;