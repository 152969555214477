import React from "react";
import Dropdown from "components/Dropdown";
import {useTypedSelector} from "helpers/reducers";
import {Notification} from "types/entities/Notification";
import {BellAlertIcon, BellIcon} from "@heroicons/react/24/outline";
import {useTranslation} from "react-i18next";
import SessionAction from "store/session/SessionAction";
import {useDispatch} from "useDispatch";
import {NotificationCount} from "pages/Front/components/Notifications/NotificationCount";
import {NotificationItem} from "pages/Front/components/Notifications/NotificationItem";

const NotificationsDropdown = () => {
    const notifications = useTypedSelector(state => state.sessionState.notifications);
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const onMarkAllReadHandler = () => {
        dispatch(SessionAction.markAllNotificationsRead()).then(r => {});
    };
    const unreadNotificationsCount = notifications.filter(notification => notification.read_at === null);
    const onMarkNotificationReadHandler = (notification: Notification) => {
        dispatch(SessionAction.markNotificationRead(notification)).then(r => {});
    };
    return (
        <Dropdown
            nextToTitle={notifications && notifications.length > 0 ? <NotificationCount count={unreadNotificationsCount.length} /> : <></>}
            title={notifications && notifications.length > 0 ? <BellAlertIcon className={"w-6 h-6"} /> : <BellIcon className={"w-6 h-6"} />}
        >
            <div className={"NotificationsScroller flex flex-col overflow-y-auto max-h-[50vh]"}>
                {notifications &&
                    notifications.length > 0 &&
                    notifications.map((notification: Notification, index) => {
                        return <NotificationItem key={index} index={index} notification={notification} onRead={onMarkNotificationReadHandler} />;
                    })}
            </div>
            {notifications && notifications.length > 0 ? (
                <button className={"underline underline-offset-2 w-full px-4 py-4 my-2 mx-2"} type={"button"} onClick={onMarkAllReadHandler}>
                    {t("Mark all read")}
                </button>
            ) : (
                <div className={"NotificationsEmpty  z-10 flex flex-row px-4 py-4 border-b border-gray-50 justify-between items-center bg-white"}>
                    {t("No notifications")}
                </div>
            )}
        </Dropdown>
    );
};

export default NotificationsDropdown;
