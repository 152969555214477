import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch} from "useDispatch";
import {UserType} from "types/enums/UserType";
import {currencyNumberFormat} from "utils/utility";
import {toast} from "react-toastify";
import {useTypedSelector} from "helpers/reducers";
import RegistrationNavigation from "pages/Front/Registration/components/RegistrationNavigation";
import {ShoppingCartItem} from "types/entities/ShoppingCartItem";
import useShoppingCart from "utils/hooks/useShoppingCart";
import {Article} from "types/entities/Article";
import ArticlesAction from "store/articles/ArticlesAction";
import CartAction from "store/cart/CartAction";
import DriverRow from "pages/Front/Registration/components/ProductSelection/components/DriverRow";
import {InformationCircleIcon} from "@heroicons/react/24/solid";
interface RegistrationProductSelectionProps {
}

const RegistrationProductSelection = (props: RegistrationProductSelectionProps) => {
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const articles = useTypedSelector(state => state.articlesState.articlesKiosk);
    const accounts = useTypedSelector(state => state.registrationState.accounts);
    const mainAccount = accounts && accounts.find(account => account.user_type === UserType.User);
    const cart = useTypedSelector(state => state.cartState.cart);
    const [canNext, setCanNext] = useState(false);
    const shoppingCartHook = useShoppingCart();
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const onSelectHandler = (article: Article, account_id: number, shoppingCartItem: ShoppingCartItem | null, quantity: number) => {
        if(isLoading) {
            return;
        }
        setIsLoading(true);

        if (shoppingCartItem === null) {
            const findedArticleForDriver = cart && cart.shoppingCartItems.find(item => item.driver_id === account_id);
            if (findedArticleForDriver) {
                shoppingCartHook.fetchRemoveCartItem(findedArticleForDriver).then(r => {
                    return shoppingCartHook.fetchSetCartItem(account_id, article, 1).then(r => {
                        setIsLoading(false);
                    });
                })
            } else {
                return shoppingCartHook.fetchSetCartItem(account_id, article, 1).then(r => {
                    setIsLoading(false);
                });
            }
        } else {
            toast.warning(t("This product for this driver is in cart."));
            setIsLoading(false);
        }
    };

    useEffect(() => {
        if ((articles && articles.length === 0) || articles === null) {
            dispatch(ArticlesAction.fetchArticlesKiosk()).then(r => {
            });
        }
    }, [articles]);

    useEffect(() => {
        setCanNext(prevState => {
            return cart?.shoppingCartItems.length === accounts.length;
        });
    }, [cart?.shoppingCartItems, accounts]);

    useEffect(() => {
        if (!cart && mainAccount) {
            dispatch(CartAction.fetchCreateCart(mainAccount)).then(r => {
                // pripravit loader
            });
        }
    }, [cart]);

    return (
        <div className={"Registration h-full flex flex-col flex-grow m-auto justify-between w-full pt-8"}>
            <div className={"RegistrationContent h-full"}>
                <div className={"max-w-3xl w-full mx-auto"}>

                    <h1 className={"text-3xl font-bold mb-0 mt-3 w-full text-center"}>{t("Product selection")}</h1>

                    <p className={"mb-8 flex flex-row items-center justify-center"}>
                        <InformationCircleIcon className={"w-6 h-6 mr-2 text-blue-600"} />
                        <span>{t("Je potrebne pre kazdeho drivera vybrat produkt.")}</span>
                    </p>

                    {articles && accounts && (
                        <>
                            <div className={"flex flex-row bg-black text-lg text-white py-4 rounded-3xl shadow-xl w-full mb-6"}>
                                <div className={"w-[3.1rem]"}></div>
                                <div className={"grid grid-cols-7 w-full"}>
                                    <div className={"col-span-2 text-center pl-6 pr-[4.6rem] relative after:h-full after:top-0 after:absolute after:right-2 after:border-r after:border-white/50 after:content-[''] after:block"}>{t("Driver")}</div>
                                    <div className={"col-span-5 text-center px-6"}>{t("Product")}</div>
                                </div>
                            </div>

                            <div className={"h-full w-full flex flex-col overflow-hidden pb-10"}>
                                <div className={"h-auto overflow-y-auto scrollbar flex justify-center"}>
                                    <div className={"w-full h-full flex flex-wrap"}>
                                        {accounts.map((account, accountIndex) => {
                                            const shoppingCartArticleForDriver: ShoppingCartItem[]|[] = (cart && cart.shoppingCartItems) ? cart.shoppingCartItems.filter(shoppingCartItem => shoppingCartItem.driver_id === account.id) : [];
                                            return (
                                                <DriverRow
                                                    account={account}
                                                    driverShoppingCartItems={shoppingCartArticleForDriver}
                                                    isLoading={isLoading}
                                                    key={accountIndex}
                                                    onSelect={onSelectHandler}
                                                />
                                            );
                                        })}
                                    </div>
                                </div>
                            </div>
                        </>
                    )}

                    <div className={"w-full justify-center flex bg-black text-white px-6 pt-4 pb-3 mb-10 rounded-3xl drop-shadow"}>
                        <table className={"table-auto w-full"}>
                            <tbody>
                                <tr>
                                    <th className={"text-left font-medium text-lg"}>Celkom k uhrade</th>
                                    <td className={"text-right font-medium text-lg"}>
                                        {currencyNumberFormat(cart?.price ? cart.price : 0)}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                </div>
            </div>
            <RegistrationNavigation allowBack={true} allowNext={canNext} linkBack={"/booking/account/liability-waiver"} linkNext={"/booking/account/product-selection"} />
        </div>
    );
};

export default RegistrationProductSelection;