import React from 'react';
import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";
import clsx from "clsx";
import {ChevronDoubleLeftIcon, ChevronDoubleRightIcon} from "@heroicons/react/24/solid";

interface RegistrationNavigationProps {
    linkBack?: string;
    linkNext?: string;
    allowBack?: boolean;
    allowNext?: boolean;
}

const RegistrationNavigation = (props: RegistrationNavigationProps) => {
    const {t} = useTranslation();
    return (
        <div className={"sticky bottom-0 left-0 right-0 h-auto w-full bg-gray-400 drop-shadow-2xl shadow-3xl"}>
            <div className={"max-w-3xl w-full mx-auto flex flex-row justify-between"}>
                {props.linkBack
                    ?
                        (<Link className={clsx(
                            "flex flex-row items-center px-4 py-3",
                            props.allowBack ? "bg-cta-800 text-contrast-800" : "text-black/20 bg-gray-400 pointer-events-none cursor-not-allowed",
                        )} to={props.linkBack}>
                            <ChevronDoubleLeftIcon className={"w-4 h-4 mr-2"} />
                            <span>{t("Back")}</span>
                        </Link>)
                    : (<div></div>)
                }
                {props.linkNext &&
                    <Link className={clsx(
                        "flex flex-row items-center px-4 py-3",
                        props.allowNext ? "bg-cta-800 text-contrast-800" : "text-black/20 bg-gray-400 pointer-events-none cursor-not-allowed",
                    )} to={props.linkNext}>
                        <span>{t("Next step")}</span>
                        <ChevronDoubleRightIcon className={"w-4 h-4 ml-2"} />
                    </Link>
                }
            </div>
        </div>
    );
};

export default RegistrationNavigation;