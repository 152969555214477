import React from "react";

const DashboardTwoColumnLayout = props => {
    const {contentLeft, contentRight} = props;
    return (
        <div className="DashboardTwoColumnLayout relative flex w-full h-full flex-row justify-stretch flex-wrap content-between items-stretch pt-6">
            <div className="basis-65 grid mt-0 grid-cols-3 gap-x-8 2xl:gap-x-24 2xl:gap-y-12 gap-y-6 flex-grow flex-shrink-0 pr-8 h-auto items-stretch justify-items-stretch">
                {contentLeft}
            </div>
            <div className="flex justify-items-stretch items-start content-between basis-35 flex-col">
                <div className="space-y-6 2xl:space-y-12 flex flex-col w-full h-full justify-between">{contentRight}</div>
            </div>
        </div>
    );
};

export default DashboardTwoColumnLayout;
