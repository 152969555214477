import React, {Suspense, useEffect, useRef} from "react";
import {useDispatch} from "../useDispatch";
import {useTypedSelector} from "helpers/reducers";
import "assets/ExtraStyle.css";
import Logo from "pages/Front/components/Logo";
import WithSuspenseLoader from "../components/WithSuspenseLoader";
import Language from "pages/Front/components/Language";
import {useTranslation} from "react-i18next";
import Popup from "pages/Front/components/Popup";
import SessionAction from "store/session/SessionAction";

const CookieConsentComponent = React.lazy(() => import("react-cookie-consent"));
const CookieConsent = props => <WithSuspenseLoader>{<CookieConsentComponent {...props} />}</WithSuspenseLoader>;

interface UnloggedLayoutProps {
    children: React.ReactNode | React.ReactNode[];
}

const UnloggedRegistrationLayout = (props: UnloggedLayoutProps) => {
    const scrollingRef = useRef<HTMLDivElement | null>(null);
    const dispatch = useDispatch();

    //if no language is set, use hall language
    const hallLanguage = useTypedSelector(state => state.hallState.hall?.language?.code);
    const language = useTypedSelector(state => state.sessionState).language?.code || hallLanguage;

    useEffect(() => {
        if(language) {
            dispatch(SessionAction.setLanguage(language));
        }
    }, []);

    useEffect(() => {
        scrollingRef.current!.scrollTo(0, 0);
        window.scrollTo(0, 0);
    }, [location.pathname]);

    return (
        <Suspense fallback={<div>Loading...</div>}>
            <div className="UnloggedLayout relative bg-gray-100 flex flex-grow">
                <div className="UnloggedLayoutOuter w-full flex-grow flex flex-col justify-center z-2 relative">
                    <div className={"UnloggedLayoutHeaderBackground border-b border-solid border-gray-600"}>
                        <div
                            className={"UnloggedLayoutHeader max-w-5xl xl:max-w-7xl relative flex justify-between sm:justify-between items-center m-auto w-full py-1 sm:py-2 2xl:py-4 px-4 lg:px-2"}
                        >
                            <Logo />
                            <div className={"relative flex flex-col justify-end items-center"}>
                                <Language />
                            </div>
                        </div>
                    </div>
                    <div className={"relative flex flex-col flex-grow w-full"}>
                        <div ref={scrollingRef} className={"UnloggedRegistrationLayoutInner flex-grow flex flex-col"} role="document">
                            {/*must be block for ios devices */}
                            <div className={"block flex-col flex-grow m-auto justify-between w-full"}>{props.children}</div>
                        </div>
                    </div>
                </div>
                <Popup />
                <div className="ref"></div>

            </div>
        </Suspense>
    );
};
export default UnloggedRegistrationLayout;
