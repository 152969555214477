import React, {useState} from "react";
import clsx from "clsx";

interface Props {
    title: string | React.ReactNode;
    children: React.ReactNode | React.ReactNode[];
    nextToTitle?: string | React.ReactNode;
}

const Dropdown = (props: Props) => {
    const [active, setActive] = useState(false);
    const [active2, setActive2] = useState(false);

    return (
        <div
            className="Dropdown w-full h-full p-3 items-center justify-center cursor-pointer flex relative"
            onMouseOut={() => setActive(false)}
            onMouseOver={() => setActive(true)}
        >
            <button className=" flex w-6 h-6 flex-col justify-center items-center" type={"button"} onClick={() => setActive(!active)}>
                <div className="items-center flex">
                    <div className="font-m text-sm leading-[0.5rem] font-normal">{props.title}</div>
                </div>
                <div className="flex">{props.nextToTitle}</div>
            </button>

            <div
                className={clsx("sm:min-w-[240px] min-w-[90vw] fixed sm:absolute right-0 sm:right-0 sm:translate-x-6 top-12 lg:top-20 overflow-hidden transform rounded-b-xl bg-white shadow-xl z-50 border-gray-100")}
                style={{maxHeight: active || active2 ? "2000px" : "0%"}}
                onMouseLeave={() => setActive(false)}
            >
                {props.children}
            </div>
        </div>
    );
};

export default Dropdown;
