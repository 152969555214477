import React from "react";
import {useForm} from "react-hook-form";
import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";
import history from "helpers/history";
import {useDispatch} from "useDispatch";
import SessionAction from "store/session/SessionAction";
import GlobalInput from "./GlobalInput";
import {IS_DEV} from "helpers/config";

const LoginForm = props => {
    const {
        register,
        handleSubmit,
        watch,
        setValue,
        reset,
        formState: {errors},
    } = useForm();
    const {t} = useTranslation();
    const dispatch = useDispatch();

    const onSubmit = data => {
        dispatch(SessionAction.login(data)).then(r => {
            if (r.success) {
                history.push("/dashboard");
            }
        });
    };

    return (
        <form className="w-full z-20" id="wf-form-ForgottenPassword-Form" method="POST" name="wf-form-ForgottenPassword-Form" onSubmit={handleSubmit(onSubmit)}>
            {/*{IS_DEV && (*/}
            {/*    <button onClick={() => handleSubmit(onSubmit({username: "testerko123456@i-o.sk", password: "testerko123456@i-o.sk"}))}>instant login</button>*/}
            {/*)}*/}
            <div className={"flex flex-col space-y-4 sm:space-y-6"}>
                <GlobalInput
                    autofocus={true}
                    label={t("Email or Username")}
                    minLength={3}
                    minLengthMessage={t("Minimum length is 3")}
                    name={"username"}
                    pattern={"/S"}
                    patternMessage={t("Enter your username or email")}
                    placeholder={t("john@gmail.com")}
                    register={register}
                    required={true}
                    type={"text"}
                />
                <GlobalInput
                    autofocus={false}
                    label={t("Password")}
                    minLength={8}
                    minLengthMessage={t("Minimum length is 8")}
                    name={"password"}
                    pattern={"/S"}
                    patternMessage={t("Enter valid password")}
                    placeholder={"*******"}
                    register={register}
                    required={true}
                    type={"password"}
                />
            </div>
            <div className="flex w-full pt-0 pr-4 leading-5 text-center no-underline justify-end ">
                <div className="font-sans-serif-g font-normal mt-2 mb-0">
                    <Link className={"text-gray-800 font-i text-xs sm:text-sm underline decoration-gray-800"} to={"/forgotten-password"}>
                        {t("Forgot your Password?")}
                    </Link>
                </div>
            </div>
            <div className="flex pt-6 2xl:pt-8 justify-center flex-wrap">
                <button
                    className="flex relative z-1 w-full max-w-xs h-10 2xl:h-12 pl-10 pr-10 pt-0 pb-0 bg-dark-800 rounded-2xl text-white text-xs uppercase justify-center items-center opacity-90 shadow-lg shadow-blue-800/50 font-medium"
                    type="submit"
                >
                    {t("Login")}
                </button>
            </div>
        </form>
    );
};
export default LoginForm;
