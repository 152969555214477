import React from "react";

export const CloseButton = ({onClick}: {onClick?: React.MouseEventHandler<HTMLButtonElement>}) => {
    return (
        <button
            className="CloseButton relative z-1 overflow-visible flex w-8 h-8 mt-4 mr-4 justify-center items-center rounded-full bg-transparent shadow-lg mr-2
                                    text-sm uppercase cursor-pointer spacing-px no-underline"
            type="button"
            onClick={onClick}
        >
            <div className="w-full h-full p-2">
                <img alt="close" src="/img/close-black.svg" />
            </div>
        </button>
    );
};

export const SubmitButton = ({
    onMouseEnter,
    onClick,
    text,
    icon}: {
    onMouseEnter?: React.MouseEventHandler;
    onClick?: React.MouseEventHandler;
    type: "submit" | "reset" | "button";
    text: string;
    icon?: React.ReactNode | null;
}) => {
    return (
        <button
            className="SubmitButton select-none flex relative z-1 h-12 px-3 py-1 bg-dark-800 rounded-xl ring-offset-2
            focus:ring-dark-800 focus:bg-white focus:text-dark-800 focus:no-underline
            active:ring-dark-800 active:bg-white active:text-dark-800 active:no-underline
            hover:ring-dark-800 hover:bg-white hover:text-dark-800 hover:no-underline
            border-dark-800 border-2 border-solid
            text-white text-xs uppercase justify-center items-center font-medium tracking-widest grow"
            type="submit"
            onClick={onClick}
            onMouseEnter={onMouseEnter}
        >
            {icon}
            <span>{text}</span>
        </button>
    );
};

export const ConfirmButton = ({onClick, type = "button"}: {onClick?: React.MouseEventHandler<HTMLButtonElement>; type: "submit" | "reset" | "button"}) => {
    return (
        <button
            className="ConfirmButton relative z-1 overflow-visible flex w-8 h-8 mt-4 mr-4 justify-center items-center rounded-full bg-transparent shadow-lg mr-2
                                    text-sm uppercase cursor-pointer spacing-px no-underline"
            type={type}
            onClick={e => onClick && onClick(e)}
        >
            <div className="w-full h-full p-2">
                <img alt="confirm" src="/img/confirm-black.svg" />
            </div>
        </button>
    );
};
