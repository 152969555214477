import {NavLink} from "react-router-dom";
import React from "react";
import NavBarItemContent from "./NavBarItemContent";
import {IS_DEV} from "helpers/config";

interface Props {
    to: string;
    img: string;
    text: React.ReactNode;
    textVisible?: boolean;
}

const NavBarItem = (props: Props) => {
    return (
        <li className="NavBarItem w-full h-auto list-type-none">
            <NavLink
                activeClassName="w--current"
                className="relative flex h-full content-start justify-start items-start sm:items-center text-gray-500"
                to={props.to}
            >
                <NavBarItemContent img={IS_DEV ? "https://booking.kart.ch/" + props.img : props.img} text={props.text} textVisible={props.textVisible} />
            </NavLink>
        </li>
    );
};

export default NavBarItem;
