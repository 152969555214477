import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import dayjs from "dayjs";
import {MIN_PERSON_BIRTHDATE_AGE} from "helpers/config";
import {FieldErrors} from "react-hook-form";
import clsx from "clsx";
import BirthdateInput, {BirthdateInputData} from "pages/Front/Registration/components/BirthdateInput";
import Dates from "utils/Dates";

interface BirthdateProps {
    errors?: FieldErrors;
    defaultValues?: any;
    name: string;
    register: any;
    setValue?: any;
    label: string;
    disabled?: boolean;
    required?: boolean;
}

const Birthdate = (props: BirthdateProps) => {
    const {t} = useTranslation();
    const [birthdate, setBirthdate] = useState<string | null>(null);
    const [year, setYear] = useState<number | null>(null);
    const [month, setMonth] = useState<number | null>(null);
    const [day, setDay] = useState<number | null>(null);
    const [isYearDisabled, setIsYearDisabled] = useState<boolean>(false);
    const [isMonthDisabled, setIsMonthDisabled] = useState<boolean>(true);
    const [isDayDisabled, setIsDayDisabled] = useState<boolean>(true);
    const [availableYears, setAvailableYears] = useState<BirthdateInputData[]>([]);
    const [availableMonths, setAvailableMonths] = useState<BirthdateInputData[]>([]);
    const [availableDays, setAvailableDays] = useState<BirthdateInputData[]>([]);
    const [resetDay, setResetDay] = useState<boolean>(false);
    const [resetMonth, setResetMonth] = useState<boolean>(false);
    const lastYear = dayjs().subtract(MIN_PERSON_BIRTHDATE_AGE, 'year').year();
    const firstYear = dayjs().subtract(100, 'year').year();

    const generateYears = () => {
        const getData = (start, end) => Array.from({length: (start - (end - 1))}, (_, i): BirthdateInputData => {
            return {value: Number(end + i), name: String(end + i)}
        });
        const data = getData(lastYear, firstYear);
        data.sort(function (a, b) {
            return b.value - a.value
        });
        setAvailableYears(data);
    }

    const generateMonths = (year: number) => {
        const currentMonth = dayjs().month();

        // posledny den v roku ak je aktualny mesiac
        const lastMonth = year === lastYear ? currentMonth : 11;

        const getData = (count) => Array.from({length: count + 1}, (_, i): BirthdateInputData => {
            return {value: Number(i), name: String(dayjs().month(i).format("MM"))}
        });
        const data = getData(lastMonth).sort(function (a, b) {
            return a.value - b.value
        });
        setAvailableMonths(data);
    };

    const generateDays = (year: number, month: number) => {
        const currentDay = dayjs().date();
        const currentMonth = dayjs().month();

        // posledny den v mesiaci a danom roku
        const lastDayInMonth = dayjs(year + "-" + dayjs().month(month).format("MM") + "-01").daysInMonth(); // TODO: doplnit year mont day 01
        // posledny mozny den, ak je aktualny rok a mesiac
        const lastDay = (year === lastYear && month === currentMonth) ? currentDay : lastDayInMonth;

        const getData = (count) => Array.from({length: count}, (_, i): BirthdateInputData => {
            return {value: Number(i + 1), name: String(dayjs().year(year).month(month).date(Number(i + 1)).format("DD"))}
        });

        const data = getData(lastDay);
        setAvailableDays(data);
    };
    const onChangeYear = (year: number) => {
        setYear(year);
        generateMonths(year);
        setResetMonth(true);
        setResetDay(true);
        setMonth(null);
        setDay(null);
        setBirthdate("");
        props.setValue(props.name, null);
    }

    const onChangeMonth = (month: number) => {
        setMonth(month);
        year && generateDays(year, month);
        setResetDay(true);
        setDay(null);
        setBirthdate("");
        props.setValue(props.name, null);
    }

    const onChangeDay = (day: number) => {
        setDay(day);
        const formattedMonth = month && dayjs().month(month).format("MM");
        const formattedDay = dayjs().date(day).format("DD")
        const finalDateDayJs = dayjs(year + "-" + formattedMonth + "-" + formattedDay, "YYYY-MM-DD");
        const finalDate = Dates.date(finalDateDayJs, new Date(), "YYYY-MM-DD");
        props.setValue(props.name, finalDate);
        setBirthdate(finalDate);
    }

    useEffect(() => {
        generateYears();
    }, []);

    useEffect(() => {
        if (year !== null && props.disabled !== true) {
            setIsMonthDisabled(false);
        } else {
            setMonth(null);
            setDay(null);
            setIsMonthDisabled(true);
            setIsDayDisabled(true);
        }
    }, [year]);

    useEffect(() => {
        if (month !== null && props.disabled !== true) {
            setIsDayDisabled(false);
        } else {
            setDay(null);
            setIsDayDisabled(true);
        }
    }, [month]);

    useEffect(() => {
        const defaultDate = props.defaultValues?.[props.name];
        if (defaultDate) {
            const defaultDay = dayjs(defaultDate).date();
            const defaultMonth = dayjs(defaultDate).month();
            const defaultYear = dayjs(defaultDate).year();
            setYear(defaultYear);
            generateMonths(defaultYear);
            setMonth(defaultMonth);
            generateDays(defaultYear, defaultMonth);
            setDay(defaultDay);

            const formattedMonth = dayjs().month(defaultMonth).format("MM");
            const formattedDay = dayjs().date(defaultDay).format("DD")
            const finalDateDayJs = dayjs(defaultYear + "-" + formattedMonth + "-" + formattedDay, "YYYY-MM-DD");
            const finalDate = Dates.date(finalDateDayJs, new Date(), "YYYY-MM-DD");
            props.setValue(props.name, finalDate);
        }
    }, [props.defaultValues?.[props.name]]);

    useEffect(() => {
        if (props.disabled) {
            setIsYearDisabled(true);
            setIsMonthDisabled(true);
            setIsDayDisabled(true);
        }
    }, [props.disabled]);

    return (
        <div className={"Birthdate"}>
            <label
                className={clsx(
                    // "block text-sm font-medium leading-6 -mb-[0.75rem] relative z-1",
                    "block text-sm font-medium text-gray-900",
                    props.errors?.[props.name] ? "text-red-500" : ""
                )}
            >
                <span>{t(props.label)} {props.required && (<em className={"text-red-500"}>*</em>)}</span>
            </label>
            <div className={"Birthdate flex flex-row space-x-4 mt-1"}>
                <BirthdateInput errors={props.errors} name={props.name} data={availableYears} onChange={onChangeYear}
                                placeholder={"Year"} disabled={isYearDisabled} defaultValue={year}/>
                <BirthdateInput errors={props.errors} name={props.name} data={availableMonths} onChange={onChangeMonth}
                                placeholder={"Month"} disabled={isMonthDisabled} reset={resetMonth}
                                callbackReset={(value) => setResetMonth(value)} defaultValue={month}/>
                <BirthdateInput errors={props.errors} name={props.name} data={availableDays} onChange={onChangeDay}
                                placeholder={"Day"} disabled={isDayDisabled} reset={resetDay}
                                callbackReset={(value) => setResetDay(value)} defaultValue={day}/>
                <input {...props.register(props.name)} type="hidden" name={props.name} value={birthdate}/>
            </div>
        </div>
    );
};

export default Birthdate;
