import React from 'react';
import {useTranslation} from "react-i18next";
import clsx from "clsx";
import {XMarkIcon} from "@heroicons/react/24/solid";

interface RegistrationModalProps {
    children: React.ReactNode | React.ReactNode[];
    title?: string;
    subtitle?: string;
    className?: string;
}

const RegistrationModal = (props: RegistrationModalProps) => {
    const {t} = useTranslation();

    const onCloseHandler = () => {
        console.log("onCloseHandler");
    }

    return (
        <div className={clsx("rounded-lg shadow-2xl h-auto overflow-hidden", props.className)}>
            {props.title && (
                <header className={"w-full py-5 px-4 bg-white rounded-tl-lg rounded-tr-lg border-b border-gray-600 flex justify-center items-center"}>
                    <h1 className={"text-2xl text-center font-bold"}>{props.title}</h1>
                </header>
            )}
            <div
                className={clsx(
                    "RegistrationModal h-full px-6 pt-6 pb-0 flex flex-col bg-white border border-white",
                    props.title ? "rounded-bl-lg rounded-br-lg" : "rounded-lg",
                )}
            >
                {/*<div className={"h-full w-full overflow-hidden"}>*/}
                {/*<div className={"h-full w-full overflow-hidden"}>*/}
                    <div className={"w-full overflow-y-auto scrollbar relative"}>
                        {props.subtitle && <h2 className={"text-xl text-center mb-4 font-medium"}>{t(props.subtitle)}</h2>}
                        {props.children}
                    </div>
                {/*</div>*/}
            </div>
        </div>
    );
};

export default RegistrationModal;