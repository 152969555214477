import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import BookingFooter from "pages/Front/Booking/BookingFooter";
import RegistrationForm from "pages/Front/Registration/components/RegistrationForm";
import {AccountType} from "types/enums/AccountType";
import {useTypedSelector} from "helpers/reducers";
import {AccountDriver} from "types/entities/AccountDriver";
import {UserType} from "types/enums/UserType";
import ButtonRegistration, {ButtonRegistrationStyle, ButtonRegistrationType} from "pages/Front/Registration/components/ButtonRegistration";
import {PlusIcon} from "@heroicons/react/24/solid";
import clsx from "clsx";
import RegistrationGuestRow from "pages/Front/Registration/components/RegistrationGuestRow";
import {openModal} from "helpers/actions";
import {ModalSize} from "pages/Front/components/Popup";
import {useDispatch} from "useDispatch";
import RegistrationModal from "pages/Front/Registration/components/RegistrationModal";
// import {DriverPrepareGroupRequest} from "src/types/entities/PreparedGroup";
import AlertConfirmation from "pages/Front/Registration/components/AlertConfirmation";
import RegistrationAction from "store/registration/RegistrationAction";
// import CartAction from "../SpeederKiosk/src/stores/Cart/CartAction";
// import ShoppingAction from "../SpeederKiosk/src/stores/Shopping/ShoppingAction";
import {toast} from "react-toastify";
import RegistrationLoading from "pages/Front/Registration/components/RegistrationLoading";
import HallAction from "store/hall/HallAction";
import {DEFAULT_HALL_ID} from "helpers/config";
import RegistrationNavigation from "pages/Front/Registration/components/RegistrationNavigation";

interface RegistrationProps {}

const Registration = (props: RegistrationProps) => {
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const accounts = useTypedSelector(state => state.registrationState.accounts);
    const account  = accounts && accounts.find(account => account.user_type === UserType.User) as AccountDriver;
    const guests = accounts.filter(account => account.user_type === UserType.Guest);
    const formDefaultValues = {...account};
    const [isDeleting, setIsDeleting] = useState<boolean>(false);

    const addGuestHandler = () => {
        dispatch(
            openModal(
                <RegistrationModal
                    className={"sm:min-w-[600px] md:min-w-[728px] lg:min-w-[768px] w-full"}
                    subtitle={"Personal information"}
                    title={"Add guest to account"}
                >
                    <RegistrationForm defaultValues={formDefaultValues} linkSuccess={"/booking/register"} type={AccountType.Guest} />
                </RegistrationModal>,
                {modalSize: ModalSize.Small}
            )
        );
    };

    const removeGuestHandler = (guest: AccountDriver) => {
        AlertConfirmation({
            message: t("Are you sure you want to delete this guest?"),
            onClick: () => {
                setIsDeleting(true);
                dispatch(RegistrationAction.fetchRemoveGuest(guest)).then(r => {
                    // remove driver product from cart
                    // const guestHaveItemInShoppingCart = cart?.shoppingCartItems && cart.shoppingCartItems.find(
                    //     item => item.driver_id === guest.id
                    // )
                    // guestHaveItemInShoppingCart && dispatch(CartAction.fetchRemoveCartItem(guestHaveItemInShoppingCart));

                    // remove from preparedGroup
                    // const driverPreparedGroup: DriverPrepareGroupRequest = {
                    //     driver_id: guest.id,
                    //     prepared_group_id: prepareGroup.id
                    // };
                    // dispatch(ShoppingAction.fetchRemoveDriverFromPreparedGroup(driverPreparedGroup));

                    setIsDeleting(false);
                    toast.success(t("Guest deleted."));
                });
            },
            title: t("Do you want to delete this guest?"),
        });
    };

    useEffect(() => {
        dispatch(HallAction.fetchHall(DEFAULT_HALL_ID)).then(r => {});
    }, []);

    return (
        <div className={"Registration h-full flex flex-col flex-grow m-auto justify-between w-full pt-8"}>
            <div className={"max-w-3xl w-full mx-auto"}>
                <h1 className={"text-3xl font-bold mb-8 mt-3 w-full text-center"}>{t("Registration")}</h1>

                <div className={"px-6 pt-6 pb-0 shadow-2xl rounded-lg h-auto flex flex-col bg-white border border-white"}>
                    <h2 className={"text-xl text-center mb-4 font-medium"}>{t("Personal information")}</h2>
                    <RegistrationForm defaultValues={formDefaultValues} type={AccountType.Main} linkSuccess={"/booking/register"} />
                </div>

                {account && (
                    <div className={"mb-10 mt-10 px-6 py-6 overflow-hidden shadow-2xl rounded-lg h-auto flex flex-col bg-white border border-white"}>
                        <div className={"flex flex-col w-auto h-auto overflow-hidden"}>
                            <div className={"flex flex-row relative flex-0 justify-between items-center mb-6 w-full"}>
                                <div className={"flex-1"}>
                                    <ButtonRegistration
                                        className={clsx("!text-base !py-2 !px-3")}
                                        style={ButtonRegistrationStyle.Primary}
                                        type={ButtonRegistrationType.Button}
                                        onClick={addGuestHandler}
                                    >
                                        <PlusIcon className={"w-4 h-4 mr-2"} />
                                        <span>{t("Add guest")}</span>
                                    </ButtonRegistration>
                                </div>
                                <div className={"flex-auto max-w-[50%]"}>
                                    <h2 className={"text-xl text-center font-medium"}>{t("Guests in your account")}</h2>
                                </div>
                                <div className={"flex-1"}></div>
                            </div>

                            <div className={"h-full w-full overflow-hidden flex flex-col rounded-bl-md rounded-br-md"}>
                                <div className={"grid grid-cols-4 bg-black text-white py-3 rounded-tl-md rounded-tr-md"}>
                                    <div className={"col-span-1 px-3 border-r border-white/50"}>{t("First name")}</div>
                                    <div className={"col-span-1 px-3 border-r border-white/50 text-center"}>{t("Last name")}</div>
                                    <div className={"col-span-1 px-3 border-r border-white/50 text-center"}>{t("Nickname")}</div>
                                    <div className={"col-span-1 px-3 text-right"}>{t("Actions")}</div>
                                </div>
                                <div className={"w-full overflow-y-auto scrollbar relative shadow-xl"}>
                                    {isDeleting && <RegistrationLoading />}
                                    {guests.length > 0 &&
                                        guests.map((guest, index) => {
                                            return <RegistrationGuestRow key={index} driver={guest} onRemove={removeGuestHandler} />;
                                        })}
                                    {guests.length === 0 && (
                                        <div className={"py-3 px-3 bg-white text-center rounded-bl-xl rounded-br-xl"}>
                                            {t("Zatial nebol pridany ziadny guest")}
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>
            <RegistrationNavigation allowNext={!!account} linkNext={"/booking/account/liability-waiver"} />
        </div>
    );
};

export default Registration;
