import React from 'react';
import "react-confirm-alert/src/react-confirm-alert.css";
import T from "components/T";
import ButtonRegistration, {ButtonRegistrationStyle} from "pages/Front/Registration/components/ButtonRegistration";
import {confirmAlert} from 'react-confirm-alert';

interface AlertConfirmationProps {
    onClick: () => void;
    title: string;
    message: string;
    children?: React.ReactNode | React.ReactNode[];
    buttons?: Array<{
        label: string;
        onClick: () => void;
        className?: string;
    }>;
    implicitlyDisabled?: boolean;
}

const AlertConfirmation = (props: AlertConfirmationProps) => {

    const confirmationHandler = (onClose) => {
        props.onClick();
        return onClose();
    }

    return confirmAlert({
        closeOnEscape: true,
        customUI: ({onClose}) => {
            return (
                <div className="p-10 bg-white shadow-md border border-gray-800 rounded">
                    <h1 className={"text-lg font-black"}>{props.title}</h1>
                    <p className={"font-light"}>{props.message}</p>
                    {props.children}
                    <div className={"flex flex-row justify-between gap-x-8 mt-8"}>
                        <ButtonRegistration
                            disabled={props.implicitlyDisabled}
                            style={ButtonRegistrationStyle.Red}
                            onClick={() => confirmationHandler(onClose)}
                        >
                            <T>Yes</T>
                        </ButtonRegistration>
                        <ButtonRegistration
                            onClick={() => {
                                onClose();
                            }}
                        >
                            <T>No</T>
                        </ButtonRegistration>
                    </div>
                </div>
            );
        },
    });
};

export default AlertConfirmation;