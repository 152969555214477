import React from 'react';
import {useTranslation} from "react-i18next";
import clsx from "clsx";

export enum ButtonRegistrationStyle {
    Black = "black",
    Gray = "gray",
    Green = "green",
    Orange = "orange",
    Red = "red",
    Disabled = "disabled",
    White = "white",
    Primary = "primary",
    Transparent = "transparent",
}

export enum ButtonRegistrationType {
    Button = "button",
    Submit = "submit",
    Reset = "reset",
}

interface ButtonRegistrationProps {
    className?: string;
    onClick: (e) => void;
    type?: ButtonRegistrationType;
    label?: string;
    children?: React.ReactNode | React.ReactNode[];
    style?: ButtonRegistrationStyle;
    disabled?: boolean;
    loading?:boolean
}

const ButtonRegistration = (props: ButtonRegistrationProps) => {
    const {t} = useTranslation();

    return (
        <button
            type={props.type ? props.type : ButtonRegistrationType.Button}
            className={clsx(
                "inline-flex flex-row justify-center items-center p-2 sm:p-3 text-base sm:text-lg rounded-md font-medium transition ease-in duration-150",
                props.style === ButtonRegistrationStyle.Black && "text-white bg-black",
                props.style === ButtonRegistrationStyle.Orange && "text-white bg-orange-400",
                props.style === ButtonRegistrationStyle.Green && "text-white bg-green-500",
                props.style === ButtonRegistrationStyle.Gray && "text-white bg-gray-800",
                props.style === ButtonRegistrationStyle.Red && "text-white bg-red-500",
                props.style === ButtonRegistrationStyle.White && "text-black bg-white !py-10 !px-16 !text-xl !font-thin shadow-lg drop-shadow",
                props.style === ButtonRegistrationStyle.Disabled && "text-white/60 bg-gray-400 pointer-events-none cursor-not-allowed",
                props.style === ButtonRegistrationStyle.Primary && "bg-cta-800 text-contrast-800 text-base sm:text-xl rounded-md",
                props.style === ButtonRegistrationStyle.Transparent && "bg-transparent text-black text-base sm:text-xl rounded-md",
                props.loading && "pointer-events-none cursor-not-allowed",
                !props.style && "bg-cta-800 text-contrast-800 text-base sm:text-xl rounded-md",
                props.className
            )}
            onClick={(e) => props.onClick(e)}
            disabled={props.disabled}
        >
            {props.loading &&
                <span>
                    <svg className="animate-spin -mt-0.5 -ml-1 mr-4 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"/>
                        <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 0 1 8-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 0 1 4 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"/>
                    </svg>
                </span>
            }
            {props.label && <span>t(props.label)</span>}
            {props.children && props.children}
        </button>
    );
};

export default ButtonRegistration;