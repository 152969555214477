export function getQueryParams() {
    function identity(e) {
        return e;
    }
    function toKeyValue(params, param) {
        const [key, value] = param.split("=");

        params[key] = params[key] ? [value].concat(params[key]) : value;
        return params;
    }

    return decodeURIComponent(window.location.search).replace(/^\?/, "").split("&").filter(identity).reduce(toKeyValue, {});
}

/**
 * @description
 * Takes an Array<V>, and a grouping function,
 * and returns a Map of the array grouped by the grouping function.
 *
 * @param list An array of type V.
 * @param keyGetter A Function that takes the the Array type V as an input, and returns a value of type K.
 *                  K is generally intended to be a property key of V.
 *
 * @returns Map of the array grouped by the grouping function.
 */
//export function groupBy<K, V>(list: Array<V>, keyGetter: (input: V) => K): Map<K, Array<V>> {
//    const map = new Map<K, Array<V>>();
export function groupBy(list, keyGetter) {
    const map = new Map();
    list.forEach(item => {
        const key = keyGetter(item);
        const collection = map.get(key);
        if (!collection) {
            map.set(key, [item]);
        } else {
            collection.push(item);
        }
    });
    return map;
}

/**
 * A simple forEach() implementation for Arrays, Objects and NodeLists
 * @private
 * @param {Array|Object|NodeList} collection Collection of items to iterate
 * @param {Function} callback Callback function for each iteration
 * @param {Array|Object|NodeList} scope Object/NodeList/Array that forEach is iterating over (aka `this`)
 * @return {Array|Object}
 */
export const forEach = function (collection, callback, scope = null) {
    const result = [];
    if (collection === null) {
        return result;
    }
    if (Object.prototype.toString.call(collection) === "[object Object]") {
        for (const prop in collection) {
            if (Object.prototype.hasOwnProperty.call(collection, prop)) {
                // @ts-ignore
                result.push(callback.call(scope, collection[prop], prop, collection));
            }
        }
    } else {
        for (let i = 0, len = collection.length; i < len; i++) {
            // @ts-ignore
            result.push(callback.call(scope, collection[i], i, collection));
        }
    }
    return result;
};

//https://egghead.io/lessons/javascript-redux-persisting-the-state-to-the-local-storage
export const loadState = () => {
    try {
        const serializedState = localStorage.getItem("state");
        if (serializedState === null) {
            return undefined;
        }
        const jsonState = JSON.parse(serializedState);

        return jsonState;
    } catch (err) {
        return undefined;
    }
};

export const saveState = state => {
    try {
        const trimmedState = {...state};
        delete trimmedState["ajaxLoading"];
        const serializedState = JSON.stringify(trimmedState);
        localStorage.setItem("state", serializedState);
    } catch (err) {
        // ignore write errors
    }
};

export const profile = (what, callback) => {
    const startTime = performance.now();

    // Do the normal stuff for this function
    callback();

    const duration = performance.now() - startTime;
    console.log(`${what} took ${duration}ms`);
};

export const Url = {
    serialize: function (obj, prefix) {
        const str = [];
        for (const p in obj) {
            if (obj.hasOwnProperty(p)) {
                const k = prefix ? prefix + "[" + p + "]" : p;
                const v = obj[p];
                // @ts-ignore
                str.push(
                    // @ts-ignore
                    v !== null && typeof v === "object" && Object.keys(v).length > 0 ? Url.serialize(v, k) : encodeURIComponent(k) + "=" + encodeURIComponent(v)
                );
            }
        }
        return str.join("&");
    },
};

export const selectMapper = (labelName, valueName) => {
    return item => ({label: item[labelName], value: item[valueName]});
};

export const bookingLoginModalCustomStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        borderWidth: "0",
        padding: "0",
        margin: "0",
        background: "transparent",
    },
    overlay: {
        backgroundColor: 'rgba(0,0,0,0.7)',
        padding: "30px 15px",
        zIndex: 10000,
    }
};
