import React from 'react';
import {useTranslation} from "react-i18next";
import clsx from "clsx";
import {FieldErrors} from "react-hook-form";
import RegistrationLoading from "pages/Front/Registration/components/RegistrationLoading";

interface InputRegistrationProps {
    register?: any;
    className?: string;
    classNameInput?: string;
    label?: string;
    name: string;
    type?: string;
    errors?: FieldErrors;
    disabled?: boolean;
    innerRef?: React.LegacyRef<HTMLInputElement>;
    onFocus?: (inputName: string) => void;
    placeholder?: string;
    id?: string;
    autoFocus?: boolean;
    onBlur?: (e) => void;
    isLoading?: boolean;
    required?: boolean;
}

const InputRegistration = (props: InputRegistrationProps) => {
    const {t} = useTranslation();

    const onFocusHandler = (e: React.FocusEvent<HTMLInputElement>) => {
        // console.log("onFocusHandler", e);
        // if(e.target.name) {
        //     console.log("onFocusHandler", e);
        //     // props.onFocus(e.target.name);
        // }
    }

    return (
        <div className={clsx(
            "Input",
            props.className
        )}>
            {props.label && (
                <label htmlFor={props.name} className={clsx(
                    // "block text-sm font-medium leading-6 -mb-[1.25rem] relative z-1",
                    "block text-sm font-medium text-gray-900",
                    props.errors?.[props.name] ? "text-red-500" : ""
                )}>
                    <span>{t(props.label)} {props.required && (<em className={"text-red-500"}>*</em>)}</span>
                </label>
            )}
            <div className={clsx(
                "relative rounded-lg overflow-hidden",
                    props.label && "mt-1",
                )}
            >
                {props.isLoading && (
                    <RegistrationLoading isLoading={props.isLoading} />
                )}
                <input
                    ref={props.innerRef}
                    {...(props.register && props.name) && props.register(props.name)}
                    name={props.name}
                    id={props.id ? props.id : props.name}
                    className={clsx(
                        // "block w-full rounded-lg border-0 pb-3 pt-5 px-4 ring-1 ring-gray-600 ring-inset focus:ring-1 focus:ring-black focus:outline-0 text-sm sm:text-base sm:leading-6 placeholder:text-gray-700",
                        "block w-full rounded-lg border-0 py-3 px-3 ring-1 ring-gray-600 ring-inset focus:ring-1 focus:ring-black focus:outline-0 text-sm sm:text-base sm:leading-6 placeholder:text-gray-700",
                        props.classNameInput,
                        props.errors?.[props.name] && "!ring-red-500",
                        props.disabled ? "bg-gray-100 text-gray-700" : "bg-white text-gray-900"
                    )}
                    disabled={props.disabled}
                    onFocus={onFocusHandler}
                    placeholder={props.placeholder}
                    type={props.type ? props.type : "text"}
                    autoFocus={props.autoFocus}
                    onBlur={props.onBlur && (props.onBlur)}
                />
            </div>
        </div>
    );
};

export default InputRegistration;