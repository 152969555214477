import React from "react";
import {IS_DEV, ROOT_STORAGE_WEB} from "helpers/config";

interface ServerImageProps {
    src: string;
    className?: string;
    alt: string;
}

export const ServerImage = (props: ServerImageProps) => {
    let src = props.src;
    if (!src) {
        return <img alt={"No image"} className={props.className ? props.className : "w-full h-full object-cover"} src={""} />;
    }

    const alternatePicture = props.src.includes("winti.tomkart.com");
    const replacePictureUrl = (src: string) => {
        switch (src) {
            case "https://winti.tomkart.com/svg/adult-helmet.svg":
                return IS_DEV ? "https://booking.kart.ch/img/Blue-Racing-Helmet.png" : "/img/Blue-Racing-Helmet.png";
            case "https://winti.tomkart.com/svg/child-helmet.svg":
                return IS_DEV ? "https://booking.kart.ch/img/Green-Racing-Helmet.png" : "/img/Green-Racing-Helmet.png";
            default:
                return IS_DEV ? "https://booking.kart.ch/img/Blue-Racing-Helmet.png" : "/img/Blue-Racing-Helmet.png";
        }
    };

    if (typeof src === "string") {
        if (src.indexOf("https:") === -1 && src.indexOf("/img/") === -1) {
            src = ROOT_STORAGE_WEB + "storage/" + src;
        }
    }

    const dev_image = src => {
        return src.includes("https://") ? src : IS_DEV ? "https://booking.kart.ch/" + src : src;
    };

    return (
        <img
            alt={props.alt}
            className={props.className ? props.className : "w-full h-full object-cover"}
            src={alternatePicture ? replacePictureUrl(src) : dev_image(src)}
        />
    );
};
