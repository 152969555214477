import {useTranslation} from "react-i18next";
import clsx from "clsx";
import {Notification, NotificationType} from "types/entities/Notification";
import {GeneralRacerAvatar} from "pages/Front/components/GeneralRacerAvatar";
import {Driver} from "types/entities/Driver";
import {driverName} from "utils/utility";
import {CheckIcon} from "@heroicons/react/24/solid";
import React from "react";

interface NotificationItemProps {
    notification: Notification;
    index: number;
    onRead: (notification: Notification) => void;
}

export const NotificationItem = (props: NotificationItemProps) => {
    const {t} = useTranslation();
    const message = props.notification.data.message || "";

    const transposedMessage = message => {
        switch (message) {
            case "ping":
                return t("Has pinged you!");
            default:
                return message;
        }
    };
    return (
        <div
            className={clsx(
                props.notification.read_at !== null ? "max-h-0 py-0 overflow-hidden" : "max-h-[320px] p-3 sm:p-3",
                "NotificationItem duration-500 ease-in-out transform-gpu z-10 flex flex-row space-x-1 xl:space-x-4 odd:bg-gray-50 border-b border-gray-50 justify-between items-center bg-white"
            )}
            data-index={props.index}
        >
            {props.notification.type === NotificationType.UserNotification && (
                <div className="relative mr-2">
                    <div className="flex items-center justify-center">
                        <GeneralRacerAvatar className={"w-10 h-10 sm:w-12 sm:h-12"} driver={props.notification.driver as Driver} />
                    </div>
                </div>
            )}
            <div className="w-max max-w-sm">
                <p className="font-medium font-sans-serif-g text-xs xl:text-base leading-3 xl:leading-5 flex flex-col">
                    <span className={"font-bold mb-1"}>
                        {props.notification.type === NotificationType.UserNotification &&
                            props.notification.driver &&
                            props.notification.driver.user_type !== "guest" && props.notification.driver.user_type !== "user" ?
                          "Speeder":
                          driverName(props.notification.driver as Driver) + " :"}
                    </span>
                    {transposedMessage(message)}
                </p>
            </div>
            <button
                className={clsx(props.notification.read_at !== null ? "text-gray-800" : "text-gray-700", "w-6 h-6 shrink-0 hover:text-gray-800")}
                type={"button"}
                onClick={() => props.notification.read_at === null && props.onRead(props.notification)}
            >
                <CheckIcon className={"w-full h-full object-cover"} />
            </button>
        </div>
    );
};
