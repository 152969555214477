import React, {Fragment, useEffect, useRef, useState} from "react";
import {useTranslation} from "react-i18next";
import {Listbox} from "@headlessui/react";
import {CheckIcon, ChevronDownIcon} from "@heroicons/react/20/solid";
import clsx from "clsx";
import {FieldErrors} from "react-hook-form";

export interface BirthdateInputData {
    id?: number;
    value: number;
    name: string;
}

interface BirthdateInputProps {
    className?: string;
    errors?: FieldErrors;
    data: BirthdateInputData[];
    onChange: (value: number) => void;
    disabled?: boolean;
    placeholder: string;
    reset?: boolean;
    callbackReset?: (value: boolean) => void;
    name: string;
    defaultValue?: string | number | null;
}

const BirthdateInput = (props: BirthdateInputProps) => {
    const {t} = useTranslation();
    const [selected, setSelected] = useState<BirthdateInputData | null>(null);
    const selectedOptionRef = useRef<HTMLLIElement>(null);

    const onChangeHandler = (data: BirthdateInputData) => {
        setSelected(data);
        props.onChange(data.value);
    };

    useEffect(() => {
        if(props.reset) {
            props.callbackReset && props.callbackReset(false);
            setSelected(null);
        }
    }, [props.reset]);

    useEffect(() => {
        if(props.defaultValue !== null) {
            const defaultValue = props.data.find(item => item.value === props.defaultValue);
            setSelected(defaultValue || null);
        }
    }, [props.defaultValue]);

    return (
        <div className={clsx(
            "BirthdateInput flex-1 relative",
                props.className
            )}>
            <Listbox value={selected} onChange={onChangeHandler} disabled={props.disabled}>

                <Listbox.Button
                    className={clsx(
                        "flex justify-between w-full text-left rounded-lg border-0 py-3 pl-3 pr-2 ring-1 ring-gray-600 ring-inset focus:ring-1 focus:ring-black focus:outline-0 text-sm sm:text-base sm:leading-6 placeholder:text-gray-700",
                        props.errors?.[props.name] && "!ring-red-500",
                        props.disabled ? "bg-gray-100 text-gray-700" : "bg-white text-gray-900",
                        (selected && !props.disabled) ? "text-gray-900" : "text-gray-700"
                    )}
                >
                    <span className="block truncate">{selected ? selected.name : t(props.placeholder)}</span>
                    <ChevronDownIcon className={"w-5 h-5"} />
                </Listbox.Button>
                <Listbox.Options
                    className={"absolute scrollbar min-w-[120px] right-0 z-2 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black/5"}
                    onLoad={() => {
                        if (selected && selectedOptionRef.current) {
                            selectedOptionRef.current.scrollIntoView({
                                behavior: "smooth",
                                block: "nearest",
                            });
                        }
                    }}
                    unmount={false}
                >
                    {props.data.map((person) => (
                        /* Use the `active` state to conditionally style the active option. */
                        /* Use the `selected` state to conditionally style the selected option. */
                        <Listbox.Option key={person.value} value={person} as={Fragment}>
                            {({active, selected}) => {
                                return (
                                    <li
                                        ref={selected ? selectedOptionRef : null}
                                        className={clsx(
                                            "relative flex flex-row cursor-pointer select-none py-2 pr-2",
                                            active || selected ? "bg-gray-900 text-white" : "bg-white text-black"
                                        )}
                                    >
                                        {selected && (
                                            <span className={"w-10"}>
                                                <CheckIcon className={"w-5 h-5 ml-2"} />
                                            </span>
                                        )}
                                        <span className={clsx("block truncate", !selected && "pl-10")}>{person.name}</span>
                                    </li>
                                );
                            }}
                        </Listbox.Option>
                    ))}
                </Listbox.Options>
            </Listbox>
        </div>
    );
};

export default BirthdateInput;
