import React, {useEffect, useState} from "react";
import {withRouter} from "react-router";
import "pages/Front/Login/Login.scss";
import {Link} from "react-router-dom";
import T from "components/T";
import LoginForm from "pages/Front/Login/components/LoginForm";
import {useDispatch} from "useDispatch";
import {DEFAULT_HALL_ID, DEFAULT_OFFICIAL_NAME} from "helpers/config";
import {getQueryParams} from "helpers/helpers";
import "assets/ExtraStyle.css";
import CardBackgroundStripes from "../components/CardBackgroundStripes";
import {CSSTransition} from "react-transition-group";
import CardBackgroundShadowRight from "../components/CardBackgroundShadowRight";
import clsx from "clsx";
import {isMobileOnly} from "react-device-detect";
import {useTypedSelector} from "helpers/reducers";
import Loader from "components/Loader";
import SessionAction from "store/session/SessionAction";
import HallAction from "store/hall/HallAction";
import {HallCode} from "types/enums/HallCode";

const Login = () => {
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        const query = getQueryParams();
        if (query.get_session) {
        }
        // window.location.href = "/booking";
    }, []);

    const dispatch = useDispatch();
    useEffect(() => {
        const timer = setTimeout(() => {
            setIsLoading(false);
        }, 50);
        return () => {
            clearTimeout(timer);
        };
    });

    const LeftSide = () => {
        const hall = useTypedSelector(state => state.hallState.hall);
        const session = useTypedSelector(state => state.sessionState);
        useEffect(() => {
            if (!hall) {
                dispatch(HallAction.fetchHall(DEFAULT_HALL_ID));
            }
            if (hall && hall.language && !session.language) {
                dispatch(SessionAction.setLanguage(hall.language));
            }
        }, [hall]);

        if (!hall) return <Loader />;
        return (
            <div
                className={clsx(
                    "LeftSide transform-gpu duration-1000 transition flex relative z-40 p-4 sm:p-8 2xl:p-12 flex-1 w-full xl:w-1/2 xl:basis-50 relative flex justify-center rounded-4xl items-center shadow-near",
                    isLoading ? "opacity-0" : "opacity-100"
                )}
            >
                <div className="flex h-full w-full min-w-[320px] z-4 flex-col justify-center items-center z-3 relative ">
                    <div
                        className={clsx(
                            "relative z-50 flex flex-col justify-center items-center p-4 py-6 sm:py-8 2xl:py-12 rounded-4xl bg-black bg-opacity-50 text-white w-full h-full backdrop-filter backdrop-blur-[3px] backdrop-saturate-[200%]"
                        )}
                    >
                        <h2 className="font-m text-2xl lg:text-4xl 2xl:text-5xl 3xl:text-6xl tracking-tighter mt-0 p-4">
                            <T>Welcome to</T>
                            <br />
                            <span>{hall ? hall.name : DEFAULT_OFFICIAL_NAME}</span>
                        </h2>
                        {/*<div className="font-i">*/}
                        {/*    <T>Don't have an account already</T>,{" "}*/}
                        {/*    <Link className={"text-gray-700"} to={"/register"}>*/}
                        {/*        <T>register</T>*/}
                        {/*    </Link>*/}
                        {/*</div>*/}
                    </div>
                </div>
                <div
                    className="CardBackgroundImage absolute inset-0 z-2 rounded-4xl w-full h-full bg-white"
                    style={{
                        backgroundImage: hall.hall_code === HallCode.Zwolle ? "url(/img/kobalt_welcome.jpg)" : "url(/img/volt_stock.jpg)",
                        backgroundSize: "cover",
                    }}
                />
                <div className={"absolute inset-0 bg-gradient-to-br from-[#00000020] to-[#00000040] rounded-4xl z-[3]"} />
                <CardBackgroundShadowRight rounded={"rounded-4xl"} />
            </div>
        );
    };

    const RightSide = () => {
        return (
            <div
                className={clsx(
                    "LoginRightSide transform-gpu duration-1000 delay-500 transition relative z-3 p-6 sm:p-8 2xl:p-16 sm:pr-10 flex-1 w-full lg:w-1/2 lg:basis-50 relative flex justify-center rounded-4xl items-center",
                    isLoading ? "opacity-0" : "opacity-100"
                )}
            >
                <div className="LoginRightSideInner flex w-full max-w-xs lg:max-w-md flex-col justify-between items-center z-4 relative">
                    <div className={"SignInTitle w-full min-h-[40px] sm:min-h-[50px] flex flex-col justify-center items-start z-50"}>
                        <h3 className="mb-6 sm:mb-8 mt-0 text-xl 2xl:text-2xl leading-7 font-m transform-none font-base">
                            <T>Sign in</T>
                        </h3>
                    </div>
                    <div className="LoginFormHolder w-full items-center flex-col flex">
                        <LoginForm />
                    </div>
                    <div className="LoginRegister w-full pt-2 lg:pt-4 2xl:pt-8 text-gray-700 flex flex-col justify-center leading-6 text-center no-underline z-4">
                        <div className="flex justify-between items-center">
                            <div className="border-b border-solid w-1/4 border-gray-700 z-50 h-[1px]" />
                            <div className="font-sans-serif-g text-xs sm:text-sm font-normal text-gray-800 z-50">
                                <T>or continue with</T>
                            </div>
                            <div className="border-b border-solid w-1/4 border-gray-700 z-50 h-[1px]" />
                        </div>
                        <div className="flex items-center justify-between self-center w-full max-w-xs mt-2 lg:mt-4 2xl:mt-8 z-40 relative">
                            <Link
                                className={
                                    "flex relative z-1 w-full h-10 2xl:h-12 pl-10 pr-10 pt-0 pb-0 rounded-2xl text-white text-xs uppercase justify-center items-center opacity-90 bg-green-500 shadow-lg shadow-green-500/50 font-medium"
                                }
                                to={"/register"}
                            >
                                <T>Register</T>
                            </Link>
                        </div>
                    </div>
                </div>
                <CardBackgroundStripes />
            </div>
        );
    };

    return (
        <div className={"Login max-w-5xl xl:max-w-7xl w-full m-auto py-1 sm:py-2 2xl:py-4 px-4 lg:px-2"}>
            <div
                className={clsx(
                    "LoginInner relative z-2 space-y-3 sm:space-y-0 flex w-full justify-center items-stretch pt-4 pb-8 2xl:pb-12",
                    isMobileOnly ? "flex-col" : "flex-row"
                )}
            >
                <CSSTransition appear={true} classNames={"slideinRight"} in={isLoading} timeout={500}>
                    <LeftSide />
                </CSSTransition>
                <CSSTransition appear={true} classNames={"slideinLeft"} in={isLoading} timeout={1000}>
                    <RightSide />
                </CSSTransition>
            </div>
        </div>
    );
};

export default withRouter(Login);
