import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import RegistrationNavigation from "pages/Front/Registration/components/RegistrationNavigation";
import RegistrationLiabilityWaiverSlide from "pages/Front/Registration/components/RegistrationLiabilityWaiverSlide";
import {Swiper, SwiperSlide} from "swiper/react";
import {useTypedSelector} from "helpers/reducers";
import ButtonRegistration, {ButtonRegistrationStyle} from "pages/Front/Registration/components/ButtonRegistration";
import {InformationCircleIcon} from "@heroicons/react/24/solid";
import ChevronLeftIcon from "@heroicons/react/24/solid/ChevronLeftIcon";
import {ChevronRightIcon} from "@heroicons/react/24/outline";
import clsx from "clsx";
import {Swiper as SwiperClass} from "swiper/types";

interface RegistrationLiabilityWaiverProps {
}

const RegistrationLiabilityWaiver = (props: RegistrationLiabilityWaiverProps) => {
    const {t} = useTranslation();
    const accounts = useTypedSelector(state => state.registrationState.accounts);
    const accountsForSignature = accounts && accounts.filter(account => account.needs_signature === true);
    const [canNext, setCanNext] = useState(false);
    const [signedDriversCount, setSignedDriversCount] = useState<number>(0);
    const [swiper, setSwiper] = useState<SwiperClass | null>(null);
    const [currentSlide, setCurrentSlide] = useState<number>(0);
    const [initLoaded, setInitLoaded] = useState<boolean>(false);
    const slideCount = accountsForSignature.length;
    const prevButtonDisabled = currentSlide === 0;
    const nextButtonDisabled = currentSlide === (slideCount - 1);

    const setSignatureHandler = () => {
        swiper && swiper.slideNext();
    };

    useEffect(() => {
        if (accountsForSignature && accountsForSignature.length > 0) {
            const signedDrivers = accountsForSignature.filter(account => account.needs_signature === true && account.signature_url !== null);
            setSignedDriversCount(signedDrivers.length);
            setCanNext(signedDrivers.length === accountsForSignature.length);

            if(swiper && signedDrivers.length !== accountsForSignature.length ) {
                const unsignedDrivers = accountsForSignature.filter(account => account.needs_signature === true && account.signature_url === null);
                if (!initLoaded && unsignedDrivers.length > 0) {
                    const firstUnsignedAccount = unsignedDrivers[0];
                    const firstUnsignedAccountIndex = accountsForSignature.findIndex(account => account.id === firstUnsignedAccount.id);
                    swiper.slideTo(firstUnsignedAccountIndex);
                    setInitLoaded(true);
                }
            }
        }
    }, [accountsForSignature]);

    return (
        <div className={"RegistrationLiabilityWaiver h-full flex flex-col flex-grow m-auto justify-between w-full pt-8"}>
            <div className={"RegistrationLiabilityWaiverContent h-full"}>

                <h1 className={"text-3xl font-bold mb-8 mt-3 w-full text-center"}>{t("Liability Waiver")}</h1>

                <div className={"mb-10 flex flex-row justify-between items-center w-full py-4 px-6 bg-white border border-white shadow-xl"}>
                    <ButtonRegistration onClick={() => swiper && swiper.slidePrev()} className={"!py-1 !px-2"} style={prevButtonDisabled ? ButtonRegistrationStyle.Disabled : ButtonRegistrationStyle.Primary}>
                        <ChevronLeftIcon className={"w-14 h-14"} />
                    </ButtonRegistration>
                    <div className={"grow"}>
                        <h2 className={"px-4 flex-1 font-normal text-center tracking-tight uppercase font-medium text-2xl 2xl:text-3xl"}>
                            {t("Required signatures")}
                            <span className={clsx("ml-3", signedDriversCount === accountsForSignature.length ? "text-green-500" : "text-red-500")}>
                                ({signedDriversCount}/{accountsForSignature.length})
                            </span>
                        </h2>
                        <p className={"flex flex-row items-center justify-center"}>
                            <InformationCircleIcon className={"w-6 h-6 mr-2 text-blue-600"} />
                            <span>{t("Pre pokracovanie musite akceptovat podmienky a vytvorit podpis.")}</span>
                        </p>
                    </div>
                    <ButtonRegistration onClick={() => swiper && swiper.slideNext()} className={"!py-1 !px-2"} style={nextButtonDisabled ? ButtonRegistrationStyle.Disabled : ButtonRegistrationStyle.Primary}>
                        <ChevronRightIcon className={"w-14 h-14"} />
                    </ButtonRegistration>
                </div>

                <div className={"max-w-3xl w-full mx-auto"}>
                    <Swiper
                        slidesPerView={1}
                        speed={1000}
                        direction={"horizontal"}
                        simulateTouch={false}
                        onSlideChange={(slide) => {
                            setCurrentSlide(slide.activeIndex);
                        }}
                        onSwiper={s => {
                            setSwiper(s);
                        }}
                        allowTouchMove={false}
                        className={"w-full h-full"}
                        wrapperClass={"w-full flex flex-row h-full"}
                    >
                        {accountsForSignature.map((account, index) => {
                            return (
                                <SwiperSlide className={"flex-shrink-0"} key={index}>
                                    <RegistrationLiabilityWaiverSlide key={index} account={account} setSignature={setSignatureHandler} />
                                </SwiperSlide>
                            );
                        })}
                    </Swiper>
                </div>

            </div>
            <RegistrationNavigation allowNext={canNext} allowBack={true} linkBack={"/booking/register"} linkNext={"/booking/account/product-selection"} />
        </div>
    );
};

export default RegistrationLiabilityWaiver;