import React, {useState} from 'react';
import {useTranslation} from "react-i18next";
import clsx from "clsx";
import ButtonRegistration from "pages/Front/Registration/components/ButtonRegistration";
import {AccountDriver} from "types/entities/AccountDriver";
import ReservationSignature from "pages/Front/Registration/components/ReservationSignature";

interface RegistrationLiabilityWaiverSlideProps {
    account: AccountDriver;
    setSignature: () => void;
}

const RegistrationLiabilityWaiverSlide = (props: RegistrationLiabilityWaiverSlideProps) => {
    const {t} = useTranslation();
    const [acceptLiabilityWaiver, setAcceptLiabilityWaiver] = useState(props.account.signature_url !== null);


    return (
        <div className={"flex flex-col mx-auto px-6 max-w-[100%] max-h-[100%] h-full xl:max-w-[90%] pb-10"}>
            <div className={"self-center pb-4"}>
                <h3 className={"text-lg text-center"}>
                    <span>{t("Liability waiver for")}</span><br/>
                    <span className={clsx(props.account.signature_url ? "text-green-500" : "text-red-500")}>
                        {props.account.name} <strong>"{props.account.nickname}"</strong> {props.account.last_name}
                    </span>
                </h3>
            </div>
            <div className={"RegistrationLiabilityWaiverSlide self-center bg-white overflow-hidden w-auto border border-white shadow-2xl rounded-3xl px-6 py-6 h-auto flex flex-col"}>
                {acceptLiabilityWaiver ? (
                    <ReservationSignature account={props.account} setSignature={props.setSignature} />
                ) : (
                    <>
                        <div className={"h-[250px] overflow-y-auto scrollbar flex justify-center"}>
                            <div className={"pl-0 pr-4 w-full h-full flex flex-wrap"}>
                                <p className={"mb-2"}>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas fringilla urna quis diam accumsan dignissim. Praesent rhoncus aliquam sapien quis feugiat. Donec justo lacus, varius in interdum quis, vestibulum et quam. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Pellentesque at malesuada justo, eu tincidunt dolor. Pellentesque scelerisque arcu at quam pellentesque, pharetra fermentum dolor placerat. Donec a felis nunc. Nulla feugiat eros condimentum molestie accumsan. Sed purus felis, faucibus eget malesuada eu, mollis in massa. Maecenas bibendum odio justo, vitae consequat est scelerisque et. Vestibulum mattis gravida magna, laoreet laoreet libero faucibus a. Curabitur et justo tortor. Nulla facilisi. Nam efficitur, arcu dictum bibendum sagittis, est tellus ullamcorper turpis, non placerat justo nulla a dui. Donec tristique odio quis urna luctus, at molestie arcu fringilla.</p>
                                <p className={"mb-2"}>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas fringilla urna quis diam accumsan dignissim. Praesent rhoncus aliquam sapien quis feugiat. Donec justo lacus, varius in interdum quis, vestibulum et quam. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Pellentesque at malesuada justo, eu tincidunt dolor. Pellentesque scelerisque arcu at quam pellentesque, pharetra fermentum dolor placerat. Donec a felis nunc. Nulla feugiat eros condimentum molestie accumsan. Sed purus felis, faucibus eget malesuada eu, mollis in massa. Maecenas bibendum odio justo, vitae consequat est scelerisque et. Vestibulum mattis gravida magna, laoreet laoreet libero faucibus a. Curabitur et justo tortor. Nulla facilisi. Nam efficitur, arcu dictum bibendum sagittis, est tellus ullamcorper turpis, non placerat justo nulla a dui. Donec tristique odio quis urna luctus, at molestie arcu fringilla.</p>
                                <p className={"mb-2"}>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas fringilla urna quis diam accumsan dignissim. Praesent rhoncus aliquam sapien quis feugiat. Donec justo lacus, varius in interdum quis, vestibulum et quam. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Pellentesque at malesuada justo, eu tincidunt dolor. Pellentesque scelerisque arcu at quam pellentesque, pharetra fermentum dolor placerat. Donec a felis nunc. Nulla feugiat eros condimentum molestie accumsan. Sed purus felis, faucibus eget malesuada eu, mollis in massa. Maecenas bibendum odio justo, vitae consequat est scelerisque et. Vestibulum mattis gravida magna, laoreet laoreet libero faucibus a. Curabitur et justo tortor. Nulla facilisi. Nam efficitur, arcu dictum bibendum sagittis, est tellus ullamcorper turpis, non placerat justo nulla a dui. Donec tristique odio quis urna luctus, at molestie arcu fringilla.</p>
                                <p className={"mb-2"}>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas fringilla urna quis diam accumsan dignissim. Praesent rhoncus aliquam sapien quis feugiat. Donec justo lacus, varius in interdum quis, vestibulum et quam. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Pellentesque at malesuada justo, eu tincidunt dolor. Pellentesque scelerisque arcu at quam pellentesque, pharetra fermentum dolor placerat. Donec a felis nunc. Nulla feugiat eros condimentum molestie accumsan. Sed purus felis, faucibus eget malesuada eu, mollis in massa. Maecenas bibendum odio justo, vitae consequat est scelerisque et. Vestibulum mattis gravida magna, laoreet laoreet libero faucibus a. Curabitur et justo tortor. Nulla facilisi. Nam efficitur, arcu dictum bibendum sagittis, est tellus ullamcorper turpis, non placerat justo nulla a dui. Donec tristique odio quis urna luctus, at molestie arcu fringilla.</p>
                                <p className={"mb-2"}>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas fringilla urna quis diam accumsan dignissim. Praesent rhoncus aliquam sapien quis feugiat. Donec justo lacus, varius in interdum quis, vestibulum et quam. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Pellentesque at malesuada justo, eu tincidunt dolor. Pellentesque scelerisque arcu at quam pellentesque, pharetra fermentum dolor placerat. Donec a felis nunc. Nulla feugiat eros condimentum molestie accumsan. Sed purus felis, faucibus eget malesuada eu, mollis in massa. Maecenas bibendum odio justo, vitae consequat est scelerisque et. Vestibulum mattis gravida magna, laoreet laoreet libero faucibus a. Curabitur et justo tortor. Nulla facilisi. Nam efficitur, arcu dictum bibendum sagittis, est tellus ullamcorper turpis, non placerat justo nulla a dui. Donec tristique odio quis urna luctus, at molestie arcu fringilla.</p>
                                <p className={"mb-2"}>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas fringilla urna quis diam accumsan dignissim. Praesent rhoncus aliquam sapien quis feugiat. Donec justo lacus, varius in interdum quis, vestibulum et quam. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Pellentesque at malesuada justo, eu tincidunt dolor. Pellentesque scelerisque arcu at quam pellentesque, pharetra fermentum dolor placerat. Donec a felis nunc. Nulla feugiat eros condimentum molestie accumsan. Sed purus felis, faucibus eget malesuada eu, mollis in massa. Maecenas bibendum odio justo, vitae consequat est scelerisque et. Vestibulum mattis gravida magna, laoreet laoreet libero faucibus a. Curabitur et justo tortor. Nulla facilisi. Nam efficitur, arcu dictum bibendum sagittis, est tellus ullamcorper turpis, non placerat justo nulla a dui. Donec tristique odio quis urna luctus, at molestie arcu fringilla.</p>
                            </div>
                        </div>
                        <div className={"self-center pt-6"}>
                            <ButtonRegistration onClick={() => setAcceptLiabilityWaiver(true)}>
                                <span>{t("Accept terms")}</span>
                            </ButtonRegistration>
                        </div>
                    </>
                )}
            </div>
        </div>
    );
};

export default RegistrationLiabilityWaiverSlide;